.contact{
    position: absolute;
    top: 343.75vw;
    width: 99%;
    font-family: "Roboto";
}

.con-top{
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    gap: 1.5625vw;
    margin-bottom: -4.6875vw;
}

.con-head{
    font-style: normal;
    font-weight: 700;
    font-size: 3.90625vw;
    text-align: right;

    color: white;
}

.con-line{
    width: 79.5vw;
    height: 0;
    border: 0.15625vw solid white;
}

.con-summary{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: left;
    margin-left: 5%;
    /* margin-bottom: -50px; */

    color: white;
}

.con-meat{
    display:flex;
    width: 90vw;
    margin-left: 5vw;
    justify-content: center;
    gap: 2vw;
}

.con-left{
    display: flex;
    width: 40vw;
    gap: 2vw;
}

.con-left-img{
    width: 22vw;
    height: 20vw;
    transition: 500ms;
    opacity: .5;
}

.con-left-img:hover{
    opacity: 1;
    transform: translateY(-0.78125vw);
}


.con-left-text{
    font-style: normal;
    font-weight: 200;
    font-size: 1.71875vw;
    margin-top: 1.5625vw;
    width: 16vw;
    /* line-height: 30px; */

    color: #6FFFD4;
}

.con-left-bottom{
    display: flex;
    position: relative;
    align-items: center;
    margin-top: -2.34375vw;
}

.con-left-action{
    font-style: normal;
    font-weight: 700;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: left;
    display: inline-block;
    position: relative;

    color: #FFFFFF;
}

.con-left-bottom:after {
    content: '';
    position: absolute;
    width: 30%;
    transform: scaleX(0);
    height: 0.15625vw;
    bottom: 1.171875vw;
    left: 0;
    background-color: #6FFFD4;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.con-left-bottom:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.con-left-arrow{
    width: 1.953125vw;
    transition: 1s;
    margin-left: 0.78125vw;
}

.con-left-bottom:hover .con-left-arrow{
    transform: translateX(0.78125vw);
}


.con-right{
    display: flex;
    width: 40vw;
    gap: 2vw;
}

.con-right-img{
    width: 22vw;
    height: 20vw;
    transition: 500ms;
    opacity: .5;
}

.con-right-img:hover{
    opacity: 1;
    transform: translateY(-0.78125vw);
}

.con-right-text{
    font-style: normal;
    font-weight: 200;
    font-size: 1.71875vw;
    margin-top: 1.5625vw;
    width: 16vw;
    text-align: right;
    /* line-height: 30px; */

    color: #6FFFD4;
}

.con-right-bottom{
    display: flex;
    position: relative;
    align-items: center;
    margin-top: -2.34375vw;
    margin-left: 5.46875vw;
}

.con-right-action{
    font-style: normal;
    font-weight: 700;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: left;
    display: inline-block;
    position: relative;

    color: #FFFFFF;
}

.con-right-bottom:after {
    content: '';
    position: absolute;
    width: 70%;
    transform: scaleX(0);
    height: 0.15625vw;
    bottom: 1.171875vw;
    left: 0;
    background-color: #6FFFD4;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.con-right-bottom:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.con-right-arrow{
    width: 1.953125vw;
    transition: 1s;
    margin-left: 0.78125vw;
}

.con-right-bottom:hover .con-right-arrow{
    transform: translateX(0.78125vw);
}