.background{
    position: absolute;
    width: 99%;
    font-family: "Roboto";
    color: #ABABAB;
    /* color: #8B8B8B; */
}

.t{
    position: absolute;
    font-style: italic;
    font-weight: 200;
    font-size: 1.40625vw;
    opacity: 0.5;
    z-index: 5;
    transition: 1s;
    cursor: default;
    /* z-index: 50; */
}

.t:hover{
    opacity: 1;
}

.d{
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 4.6875vw;
    opacity: 0.5;
    z-index: 5;
    transition: 1s;
    cursor: default;
    /* z-index: 50; */
}

.d:hover{
    opacity: 1;
}

.ar{
    position: absolute;
    opacity: 0.5;
    z-index: 5;
    transition: 1s;
    width: 12vw;
}

.ar:hover{
    opacity: 1;
}

.back-img{
    width: 102.2vw;
    height: 60vw;
    margin-left: -2vw;
    margin-bottom: -1vw;
    z-index: -10;
}

.back-mb{
    margin-bottom: -2vw;
}

.rot{
    transform: rotate(180deg);
}


.t-1{
    margin-left: 25vw;
    margin-top: 8vw;
}

.t-2{
    margin-left: 75vw;
    margin-top: 17vw;
}

.t-3{
    margin-left: 6vw;
    margin-top: 22vw;
}

.t-4{
    margin-left: 65vw;
    margin-top: 40vw;
}

.t-5{
    margin-top: 84vw;
    margin-left: 47vw;
}

.t-6{
    margin-top: 103.3vw;
    margin-left: 3vw;
}

.t-7{
    margin-top: 105vw;
    margin-left: 60vw;
}

.t-8{
    margin-top: 112vw;
    margin-left: 20vw;
}

.t-9{
    margin-top: 141.6vw;
    margin-left: 55vw;
}

.t-10{
    margin-top: 149vw;
    margin-left: 89vw;
}

.t-11{
    margin-top: 167vw;
    margin-left: 3vw;
}

.t-12{
    margin-top: 180vw;
    margin-left: 70vw;
}

.t-13{
    margin-top: 212vw;
    margin-left: 8vw;
}

.t-14{
    margin-top: 220vw;
    margin-left: 85vw;
}

.t-15{
    margin-top: 230vw;
    margin-left: 1vw;
}

.t-16{
    margin-top: 250vw;
    margin-left: 20vw;
}

.t-17{
    margin-top: 265vw;
    margin-left: 90vw;
}

.t-18{
    margin-top: 283vw;
    margin-left: 30vw;
}

.t-19{
    margin-top: 297vw;
    margin-left: 86vw;
}

.t-20{
    margin-top: 308vw;
    margin-left: 6vw;
}

.t-21{
    margin-top: 327vw;
    margin-left: 60vw;
}

.t-22{
    margin-top: 353vw;
    margin-left: 30vw;
}

.t-23{
    margin-top: 369vw;
    margin-left: 50vw;
}

.t-24{
    margin-top: 376vw;
    margin-left: 60vw;
}


.d-1{
    z-index: 50;
    margin-top: -2.5vw;
    margin-left: 20vw;
}

.d-2{
    margin-top: 4vw;
    margin-left: 62vw;
}

.d-3{
    margin-top: 30vw;
    margin-left: 12vw;
}

.d-4{
    margin-top: 68vw;
    margin-left: 85vw;
}

.d-5{
    margin-top: 71.5vw;
    margin-left: 4vw;
}

.d-6{
    margin-top: 90vw;
    margin-left: 37vw;
}

.d-7{
    margin-top: 137vw;
    margin-left: 76vw;
}

.d-8{
    margin-top: 138vw;
    margin-left: 7vw;
}

.d-9{
    margin-top: 165vw;
    margin-left: 89vw;
}

.d-16{
    margin-top: 175.5vw;
    margin-left: 11.5vw;
}

.d-10{
    margin-top: 206vw;
    margin-left: 58vw;
}

.d-11{
    margin-top: 216vw;
    margin-left: 9vw;
}

.d-12{
    margin-top: 240vw;
    margin-left: 38vw;

}

.d-13{
    margin-top: 253vw;
    margin-left: 5vw;
}

.d-14{
    margin-top: 258vw;
    margin-left:  85vw;
}

.d-15{
    margin-top: 270vw;
    margin-left: 20vw;
}

.d-17{
    margin-top: 287vw;
    margin-left: 47vw;
}

.d-18{
    margin-top: 294vw;
    margin-left: 10vw;
}

.d-19{
    margin-top: 313vw;
    margin-left: 65vw;
}

.d-20{
    margin-top: 347vw;
    margin-left: 80vw;
}

.d-21{
    margin-top: 370vw;
    margin-left: 43vw;
}

.d-22{
    margin-top: 375vw;
    margin-left: 88vw;
}



.ar-1{
    margin-top: 12vw;
    margin-left: 50vw;
}

.ar-2{
    margin-top: 15vw;
    margin-left: 3vw;
}

.ar-3{
    margin-top: 45vw;
    margin-left: 13vw;
}

.ar-4{
    margin-top: 75vw;
    margin-left: 40vw;
}

.ar-5{
    margin-top: 110vw;
    margin-left: 78vw;
}

.ar-5{
    margin-top: 138vw;
    margin-left: 60vw;
}

.ar-6{
    margin-top: 151vw;
    margin-left: 1vw;
}

.ar-7{
    margin-top: 184vw;
    margin-left: 40vw;
}

.ar-8{
    margin-top: 205vw;
    margin-left: 7vw;
}

.ar-9{
    margin-top: 234vw;
    margin-left: 70vw;
}

.ar-10{
    margin-top: 249vw;
    margin-left: 35vw;
}

.ar-11{
    margin-top: 280vw;
    margin-left: 3vw;
}

.ar-12{
    margin-top: 301vw;
    margin-left: 65vw;
}

.ar-13{
    margin-top: 326vw;
    margin-left: 13vw;
}

.ar-14{
    margin-top: 345vw;
    margin-left: 19.5vw;
}

.ar-15{
    margin-top: 354vw;
    margin-left: 52vw;
}

.ar-16{
    margin-top: 378vw;
    margin-left: 8vw;
}

/* .tag-1{
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transform-origin: bottom;

    margin-top: 100px;
    margin-left: 100px;
} */

/* .bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: linear;
}
@keyframes bounce-1 {
    0%   { transform: translateY(0); }
    25%  { transform: translateX(-10px); }
    75%  { transform: translateY(10px);}
    100% { transform: translateY(0); }
}

.bounce-2 {
    animation-name: bounce-1;
    animation-timing-function: linear;
}
@keyframes bounce-1 {
    0%   { transform: translateY(0px); }
    25%  { transform: translateX(10px); }
    75%  { transform: translateY(-10px);}
    100% { transform: translateY(0px); }
} */