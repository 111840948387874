.landing{
    position: absolute;
    top: 6.25vw;
    width: 99%;
    font-family: 'Roboto';
}

.landing-left{
    text-align: right;
    width: 40%;
}

.landing-name{
    font-style: normal;
    font-weight: 700;
    font-size: 5.46875vw;
    margin-bottom: -1.953125vw;

    color: #FFFFFF;
}

.landing-role{
    font-style: normal;
    font-weight: 300;
    font-size: 2.65625vw;
    margin-bottom: -1.015625vw;

    color: #6FFFD4;
}

.landing-location{
    font-style: normal;
    font-weight: 200;
    font-size: 1.640625vw;

    color: #FFFFFF;
}

.landing-right{
    width: 35%;
    margin-left: 57%;
    text-align: right;
    margin-bottom: -15.625vw;
}

.landing-summary{
    display: flex;
    align-items: center;
    gap: 0.78125vw;
}

.summary-text{
    font-style: normal;
    font-weight: 700;
    font-size: 1.171875vw;
    line-height: 1.875vw;
    text-align: left;

    color: #6FFFD4;
}

.summary-line{
    width: 100%;
    height: 0;
    border: 0.078125vw solid #6FFFD4;
}

.landing-headline{
    display: flex;
}

.landing-headline b{
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
    margin-bottom: -1.5625vw;

    color: #FFFFFF;
    overflow: hidden;
    border-right: .15em solid #6FFFD4;
    white-space: nowrap;
    animation: 
        typing 5s forwards;
    width: 0;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }


.landing-description{
    font-style: normal;
    font-weight: 150;
    font-size: 1.8vw;

    color: #FFFFFF;
}

.landing-button{
    font-style: normal;
    font-weight: 700;
    font-size: 1.953125vw;
    outline: none;
    border: none;

    color: #202020;
    width: 14.53125vw;
    height: 4.453125vw;

    background: #6FFFD4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.390625vw;

    transition: all 0.5s;
    cursor: pointer;
}

.landing-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.landing-button span:after {
content: '\00bb';
position: absolute;
opacity: 0;
top: 0;
right: -1.5625vw;
transition: 0.5s;
}

.landing-button:hover span {
padding-right: 1.5625vw;
}

.landing-button:hover span:after{
opacity: 1;
right: 0;
}

.landing-img{
    width: 35vw;
    height: 22vw;
    margin-left: 19.53125vw;
    opacity: 0.5;
    transition: 500ms;

    animation-duration: 6s;
    animation-iteration-count: infinite;
    transform-origin: bottom;

}

.landing-img:hover{
    opacity: 1
}

.bounce-1 {
    animation-name: bounce;
    animation-timing-function: linear;
}

@keyframes bounce {
    0%   { transform: translateY(0); }
    25%  { transform: translateY(-0.78125vw); }
    75%  { transform: translateY(0.78125vw);}
    100% { transform: translateY(0); }
}