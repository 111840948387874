.navbar {
    display: flex;
    height: 7.03125vw;
    width: 102vw;
    margin-left: -2.5vw;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);  

    position: fixed;
    top: 0;
    /* transition: top 0.5s */


    transition: 0.3s linear;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 40;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    /* padding: 0 7%; */
}

.hidden{
    height: 7.03125vw;
    width: 102vw;
    z-index: 40;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -7.03125vw;
    transition: 0.3s linear;

}

.nav-logo{
    width: 7.8125vw;
    height: 7.8125vw;
    margin-left: 3.515625vw;
    margin-top: 0.5vw;
    transition: 1s;
}

.nav-logo:hover{
    transform: translateY(-.5vw);
}

.nav-right{
    display: flex;
    justify-content: flex-end;
}

.nav-item{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1.25vw;
    margin-right: 6.25vw;
    color: #ABABAB;
    display: inline-block;
    position: relative;


    background-image: linear-gradient(
      to right,
      #6FFFD4,
      #6FFFD4 50%,
      #ABABAB 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
}
  
.nav-item:before {
    content: '';
    background: #6FFFD4;
    display: block;
    position: absolute;
    bottom: 0.234375vw;
    left: 0;
    width: 0;
    height: 0.078125vw;
    transition: all 0.3s ease-in-out;
}
  
.nav-item:hover {
   background-position: 0;
  }
  
.nav-item:hover::before {
    width:100%;
  }


.nav-button{
    width: 9.453125vw;
    height: 3.515625vw;
    border: 0.078125vw solid #6FFFD4;
    border-radius: 0.390625vw;
    background: none;
    outline: none;
    margin-top: 0.15625vw;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1.25vw;
    margin-right: 6.25vw;

    color: #6FFFD4;
    transition: all 0.5s;
    cursor: pointer;
}

.nav-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.nav-button span:after {
content: '\00bb';
position: absolute;
opacity: 0;
top: 0;
right: -1.5625vw;
transition: 0.5s;
}

.nav-button:hover span {
padding-right: 1.171875vw;
}

.nav-button:hover span:after{
opacity: 1;
right: 0;
}