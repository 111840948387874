.experience{
    position: absolute;
    top: 132.8125vw;
    width: 99%;
    font-family: "Roboto";
}

.exp-top{
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    gap: 1.5625vw;
    margin-bottom: -4.6875vw;
}

.exp-head{
    font-style: normal;
    font-weight: 700;
    font-size: 3.90625vw;
    text-align: right;

    color: white;
}

.exp-line{
    width: 79.5vw;
    height: 0;
    border: 0.15625vw solid white;
}

.exp-summary{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: left;
    margin-left: 5%;
    /* margin-bottom: -50px; */

    color: white;
}

.exp-meat{
    margin-left: 3vw;
}


.exp-img{
    position: absolute;
    width: 85vw;
    height: 35vw;
    margin-left: 6vw;
    z-index: 0;
    margin-top: -3vw;
}

.exp-companies{
    display: flex;
    width: 28vw;
    justify-content: space-between;
    align-items: center;
    margin-left: 18vw;
    margin-top: 5vw;

}

.company{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953vw;
    z-index: 10;

    transition: .5s;
    color: white;
    cursor: pointer;
}

.company:hover{
    font-weight: 700;
    color: #6FFFD4;
}

/* .company:focus{
    font-weight: 700;
    color: #6FFFD4;
} */


.exp-content{
    margin-left: 20vw;
}

.work{
    font-family: "Roboto";
    width: 50vw;
}

.work-role{
    font-style: normal;
    font-weight: 700;
    font-size: 2.22vw;
    margin-top: 3vw;
    margin-left: -2vw;
    position: absolute;

    color: #FFFFFF;
}

.work-time{
    font-style: normal;
    font-weight: 200;
    font-size: 1.54vw;
    margin-left: 2vw;
    margin-top: 6vw;
    position: absolute;


    color: #FFFFFF;
}

.work-desc{
    font-style: normal;
    font-weight: 200;
    font-size: 1.54vw;
    line-height: 1.71875vw;

    color: #FFFFFF;

}

.bull-1{
    position: absolute;
    margin-left: 3.2vw;
    margin-top: 9.5vw;
    width: 50vw;
}

.bull-2{
    position: absolute;
    margin-left: 6.4vw;
    margin-top: 15.2vw;
    width: 50vw;
}

.bull-3{
    position: absolute;
    margin-left: 9.8vw;
    margin-top: 21vw;
    width: 50vw;
}