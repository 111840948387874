.projects{
    position: absolute;
    top: 200.3125vw;
    width: 99%;
    font-family: "Roboto";
}

.projects-top{
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    gap: 1.5625vw;
    margin-bottom: -4.6875vw;
}

.projects-line{
    width: 79.5vw;
    height: 0;
    border: 0.15625vw solid #6FFFD4;
}

.projects-head{
    font-style: normal;
    font-weight: 700;
    font-size: 3.90625vw;
    text-align: right;

    color: #6FFFD4;
}

.projects-summary{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: right;
    margin-right: 5vw;
    margin-bottom: 3.90625vw;

    color: #6FFFD4;
}

.proj{
    font-family: "Roboto";
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    justify-content: center;
    margin-bottom: 10vw;
}

.proj-left{
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin-left: -4vw;
}

.proj-top{
    display: flex;
    width: 23vw;
    justify-content: space-between;
    margin-left: 2vw;
}

.proj-tool{
    font-style: normal;
    font-weight: 200;
    font-size: 1.4vw;
    cursor: default;

    color: #6FFFD4;
}

.proj-tool:hover{
    transition: .5s;
    font-weight: 600;
}

.proj-edge-top{
    position: absolute;
    margin-left: 26.2vw;
    margin-top: 1.8vw;
    width: 10vw;
}

.proj-img{
    border-radius: .2vw;
    width: 35vw;
    height: 25vw;
    margin-top: -0.5vw;
    margin-left: 2vw;
    z-index: 10;


    opacity: 0.5;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    transition: 1s;
}

.proj-img:hover{
    cursor: pointer;
    background-color: #6FFFD4;
    opacity: 0.8;
    filter: none;
    /* -webkit-filter: none; */
}

.proj-bottom{
    display: flex;
    width: 35vw;
    justify-content: space-between;
    margin-left: 2vw;
    margin-top: -0.5vw;
    /* margin-left: 10vw; */
}

.proj-edge-bottom{
    transform: rotate(180deg);
    width: 10vw;
    height: 12vw;
    margin-top: -10.5vw;
    margin-left: -1.3vw;
    z-index: 0;
}

.proj-right{
    display: flex;
    flex-direction: column;
    width: 25vw;
}

.proj-right-sides{
    display: flex;
    flex-direction: column;
    width: 27vw;
    margin-right: 5vw;
    align-items: right;
    text-align: right;
}

.proj-name{
    font-style: normal;
    font-weight: 700;
    font-size: 3.125vw;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-bottom: 2vw;
    text-decoration: none;
    margin-top: 2vw;

    color: #6FFFD4;
}

.proj-name:after {
    content: '';
    position: absolute;
    width: 46%;
    transform: scaleX(0);
    height: 0.234375vw;
    bottom: 0;
    left: 0;
    background-color: #6FFFD4;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.proj-name:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.proj-name-sides{
    font-style: normal;
    font-weight: 700;
    font-size: 3.125vw;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-bottom: 2vw;
    text-decoration: none;
    margin-top: 2vw;

    color: #6FFFD4;
}

.proj-name-sides:after {
    content: '';
    position: absolute;
    width: 39%;
    transform: scaleX(0);
    height: 0.234375vw;
    bottom: 0;
    left: 16.5vw;
    background-color: #6FFFD4;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.proj-name-sides:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.proj-desc{
    font-style: normal;
    font-weight: 200;
    font-size: 1.5625vw;
    margin-top: -1.5vw;
    margin-bottom: 3vw;
    /* line-height: 24px; */

    color: #6FFFD4;
}