.about{
    position: absolute;
    top: 62.5vw;
    width: 99%;
    font-family: 'Roboto';
}

.about-top{
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    gap: 1.5625vw;
    margin-bottom: -4.6875vw;
}

.about-line{
    width: 79.5vw;
    height: 0;
    border: 0.15625vw solid #6FFFD4;
}

.about-head{
    font-style: normal;
    font-weight: 700;
    font-size: 3.90625vw;
    text-align: right;

    color: #6FFFD4;
}

.about-summary{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: right;
    margin-right: 5vw;
    margin-bottom: -3.90625vw;

    color: #6FFFD4;
}

.about-main{
    display: flex;
    gap: 10%;
    width: 90%;
    margin-left: 5%;
}



/* .about-left{
    width: 50%;
    margin-left: 5%;
} */

.about-quote{
    /* font-family: "Arial"; */
    font-style: normal;
    font-weight: 700;
    font-size: 3.90625vw;
    /* line-height: 61px; */
    color: #6FFFD4;

}

.about-top-desc{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    margin-top: -2.734375vw;

    color: #6FFFD4;
}

.about-bottom-desc{
    font-style: normal;
    font-weight: 200;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    margin-top: -1.171875vw;

    color: #6FFFD4;
}

.about-bottom{
    display: flex;
    position: relative;
    align-items: center;
    margin-top: -1.5625vw;
    width: 30vw;
}

.about-action{
    font-style: normal;
    font-weight: 700;
    font-size: 1.953125vw;
    line-height: 2.34375vw;
    text-align: left;
    display: inline-block;
    position: relative;

    color: #FFFFFF;
}

.about-bottom:after {
    content: '';
    position: absolute;
    width: 88%;
    transform: scaleX(0);
    height: 0.15625vw;
    bottom: 1.171875vw;
    left: 0;
    background-color: #6FFFD4;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.about-bottom:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.about-arrow{
    width: 1.953125vw;
    transition: 1s;
    margin-left: 0.78125vw;
}

.about-bottom:hover .about-arrow{
    transform: translateX(10px);
}


.about-yash{
    width: 31.25vw;
    height: 35.15625vw;
    margin-top: 4.6875vw;

    transition: 500ms;
    opacity: .5;
}

.about-yash:hover{
    opacity: 1;
    transform: translateY(-0.78125vw);
}