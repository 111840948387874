body {
    background: #202020;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    /* animation-delay: 3s; */
    /* animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; */
}
  
/* @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
} */


.everything{
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    /* animation-fill-mode: forwards; */
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

/* .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
} */

html {
    scroll-behavior: smooth;
  }